import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { DropdownMenu, DropdownItem,Col, Row, Search, Overlay, Icon, Image as VanImage, Popup, Picker, Button,PullRefresh,List,Empty } from 'vant';
import 'vant/lib/index.css';

Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Search);
Vue.use(Overlay);
Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Button);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Empty);

import '@/assets/css/main.css'
import '@/assets/css/all.scss'

import Config from './script/config';
import Utils from './script/utils';
// import User from './script/user';
import Http from './script/http';
import AMapLoader from '@amap/amap-jsapi-loader';
import CompanyInfo from "@/components/CompanyInfo";
import ChanyeInfo from "@/components/ChanyeInfo";
import BasePicker from "@/components/BasePicker";
import FlatList from "@/components/FlatList";

Vue.config.productionTip = false

window._AMapSecurityConfig = {
  securityJsCode: 'b1bde1c73a918f7c6ad8af9a5458a156' // 输入你的jscode
}

Vue.component('company-info',CompanyInfo);
Vue.component('chanye-info',ChanyeInfo);
Vue.component('base-picker',BasePicker);
Vue.component('flat-list',FlatList);

// Vue.prototype.$moment = Moment;
Vue.prototype.$config = Config;
Vue.prototype.$utils = Utils;
Vue.prototype.$http = Http;
Vue.prototype.$AMapLoader = AMapLoader;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
