import Vue from 'vue'
let self = new Vue();

export default {
    setToken(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('token',v ? v : '');
        }else{
            return self.$utils.sessionSet('token',v ? v : '');
        }
    },
    getToken(){
        if(self.$isMobile){
            return self.$utils.sessionGet('token');
        }else {
            return self.$utils.sessionGet('token');
        }
    },

    setAvatar(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('avatar',v ? v : '');
        }else{
            return self.$utils.sessionSet('avatar',v ? v : '');
        }
    },
    getAvatar(){
        if(self.$isMobile){
            return self.$utils.sessionGet('avatar');
        }else {
            return self.$utils.sessionGet('avatar');
        }
    },

    setOpenId(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('openid',v ? v : '');
        }else{
            return self.$utils.sessionSet('openid',v ? v : '');
        }
    },
    getOpenId(){
        if(self.$isMobile){
            return self.$utils.sessionGet('openid');
        }else {
            return self.$utils.sessionGet('openid');
        }
    },

    setMobile(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('mobile',v ? v : '');
        }else{
            return self.$utils.sessionSet('mobile',v ? v : '');
        }
    },
    getMobile(){
        if(self.$isMobile){
            return self.$utils.sessionGet('mobile');
        }else {
            return self.$utils.sessionGet('mobile');
        }
    },

    setUserName(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('username',v ? v : '');
        }else{
            return self.$utils.sessionSet('username',v ? v : '');
        }
    },
    getUserName(){
        if(self.$isMobile){
            return self.$utils.sessionGet('username');
        }else {
            return self.$utils.sessionGet('username');
        }
    },

    setNickName(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('nickname',v ? v : '');
        }else{
            return self.$utils.sessionSet('nickname',v ? v : '');
        }
    },
    getNickName(){
        if(self.$isMobile){
            return self.$utils.sessionGet('nickname');
        }else {
            return self.$utils.sessionGet('nickname');
        }
    },

    setUid(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('uid',v ? v : '');
        }else{
            return self.$utils.sessionSet('uid',v ? v : '');
        }
    },
    getUid(){
        if(self.$isMobile){
            return self.$utils.sessionGet('uid');
        }else {
            return self.$utils.sessionGet('uid');
        }
    },

    setContact(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('contact',v ? v : '');
        }else{
            return self.$utils.sessionSet('contact',v ? v : '');
        }
    },
    getContact(){
        if(self.$isMobile){
            return self.$utils.sessionGet('contact');
        }else {
            return self.$utils.sessionGet('contact');
        }
    },

    setEmail(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('email',v ? v : '');
        }else{
            return self.$utils.sessionSet('email',v ? v : '');
        }
    },
    getEmail(){
        if(self.$isMobile){
            return self.$utils.sessionGet('email');
        }else {
            return self.$utils.sessionGet('email');
        }
    },

    setSex(v){
        if(self.$isMobile){
            return self.$utils.sessionSet('sex',v ? v : '');
        }else{
            return self.$utils.sessionSet('sex',v ? v : '');
        }
    },
    getSex(){
        if(self.$isMobile){
            return self.$utils.sessionGet('sex');
        }else {
            return self.$utils.sessionGet('sex');
        }
    },

    logout(){
        self.$utils.sessionClear();
    },

    getAll() {
        let _all = {};
        if(self.$isMobile) {
            let keys = Object.keys(localStorage);
            for (let i = 0; i < keys.length; i++) {
                _all[keys[i]] = localStorage.getItem(keys[i]);
            }

            return _all;
        }else{
            let sessionKeys = Object.keys(sessionStorage);
            for (let i = 0; i < sessionKeys.length; i++) {
                _all[sessionKeys[i]] = sessionStorage.getItem(sessionKeys[i]);
            }

            return _all;
        }
    }
}
