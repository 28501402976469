<template>
  <div>
    <div class="bd-radius-4 hide pd-10 box-shadow flex flex-row flex-ai rel" @click="showPop">
      <div class="f12 mg-r-15 text-line-1" :class="value ? 'c-dark-1' : 'c-dark-5'">{{label ? label : placeholder}}</div>
      <van-icon name="arrow-down" class="abs" style="right:10px;" size="10px" color="#aaa"/>
    </div>
    <van-popup v-model="show" get-container="body" round position="bottom">
      <van-picker
        value-key="name"
        show-toolbar
        :columns="list"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "BasePicker",
  data() {
    return {
      show:false,
      label:'',
      value:'',
      item:null,
      list:[]
    }
  },
  props:{
    placeholder:{
      type:String,
      default:'请选择'
    },
    url:{
      type:String,
      default:''
    },
    isAll:{
      type:Boolean,
      default:true
    },
    autoLoad:{
      type:Boolean,
      default:true
    },
    allLabel:{
      type:String,
      default:''
    },
    datas:{
      type:Object,
      default(){
        return {}
      }
    },
    listKey:{
      type:String,
      default:''
    },
  },
  model:{
    prop:'value',
    event:'change'
  },
  created() {
  },
  mounted() {
    if(this.autoLoad) {
      this.getList();
    }
  },
  methods: {
    getList(){
      let _this = this;
      if(this.url){
        this.$http.post({
          url:this.url,
          data:this.datas,
          success(res){
            let _list = [{name:_this.allLabel,id:''}];

            if(_this.allLabel && _this.isAll){
              _list.push.apply(_list, _this.listKey ? res[_this.listKey] : res)

              _this.list = _list;
            }else {
              _this.list = _this.listKey ? res[_this.listKey] : res;
            }
          }
        })
      }
    },
    showPop(){
      this.show = true;
    },
    onConfirm(e){
      console.log(e);
      this.label = e.name;
      this.value = e.id;

      this.show = false;

      this.$emit('change',this.value);
    }
  },
  watch:{
    url(n,o){
      // this.value = '';
      // this.label = '';
      if(n !== o) {
        this.getList();
      }
    },
    datas:{
      handler(n,o){
        if(JSON.stringify(n) !== JSON.stringify(o)) {
          this.value = '';
          this.label = '';
          this.getList();
        }
      },
      deep:true
    }
  }
}
</script>
