<template>
  <div class="rel h100 w100">
    <div id="container" class="h100 w100"></div>
    <div class="abs" style="top:0;left:0;right:0;height:130px;background-color: rgba(255,255,255,.5);z-index: 2;">
<!--      <div class="flex flex-row flex-ai f-b flex-jc-between pd-l-r-25 pd-t-b-10 dark-5 f14 bd-b-w-1 bd-style-solid bd-dark-9">-->
<!--        <div :class="index === 'resources' ? 'blue-2' : ''" @click="changeTab('resources')">资源地图</div>-->
<!--        <div :class="index === 'chanye' ? 'blue-2' : ''" @click="changeTab('chanye')">产业地图</div>-->
<!--        <div :class="index === 'kaocha' ? 'blue-2' : ''" @click="changeTab('kaocha')">考察地图</div>-->
<!--      </div>-->
      <div class="pd-l-r-15">
        <div class="flex flex-row flex-ai flex-jc-between mg-t-15">
          <div class="flex flex-1 flex-col flex-ai flex-jc" v-for="(item,index) in dictList" @click="changeDict(item)">
            <img :src="item.img" style="height: 34px;width:34px;"/>
            <div class="mg-t-5 f12" :class="chanyeActive.id === item.id ? 'blue-0' : 'dark-2'">{{item.full_name}}</div>
          </div>
        </div>
        <van-dropdown-menu class="diy-dropmenu mg-t-10" active-color="#496BE6">
          <van-dropdown-item :title="hangyeActive ? hangyeActive.name : '相关行业'" get-container="body" ref="item" title-class="f12">
            <div class="bd-t-w-1 bg-light bd-dark-10 pd-15 bd-style-solid" style="border-radius: 0 0 5px 5px;">
              <van-row gutter="20">
                <van-col span="12" v-for="(item,index) in hangyeList" class="mg-b-10" :class="hangyeList.length % 2 !== 0 && index + 1 === hangyeList.length ? 'pd-r-10' : ''" @click="changeHangye(item)">
                  <div class="flex flex-row flex-ai pd-l-20 f12 h-30 lh-30 bd-radius-2" :class="hangyeActive && (hangyeActive.id === item.id) ? 'bg-blue-0' : 'bg-gray-5'">
                    <img :src="item.img" width="13" height="13" class="mg-r-5 vm"/>
                    {{item.name}}
                  </div>
                </van-col>
              </van-row>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <div class="dark-2 f12 mg-t-15" v-if="(infoType === 'chanye' || !infoType) && markers.length <= 0 && !info">点击相应区块，查看产业信息</div>
        <div class="dark-2 f12 mg-t-15" v-if="infoType === 'chanye' && info">点击空白处取消选择</div>
      </div>
    </div>
    <div class="abs bd-radius-4 f12 text-center" style="padding:3px;bottom:160px;right:20px;background-color: rgba(255,255,255,.5);" @click="showBoxs">
      <img src="/static/image/yuanqu_on.png" width="20" class="vm" v-if="polygonShow"/>
      <img src="/static/image/yuanqu_off.png" width="20" class="vm" v-else/>
      <div class="mg-t-5" :class="polygonShow ? 'blue-0' : 'dark-4'">产业</div>
    </div>
    <div class="abs bg-light box-shadow" :style="{height:infoHeight+'px',top:infoTop+'px'}" style="left:0;right:0;border-radius: 10px 10px 0 0;z-index: 9;transition: all .3s;" @click="openInfo" v-if="info">
      <div class="rel flex flex-col h100">
        <div v-if="showInfo" class="bg-light hide abs flex flex-row flex-ai flex-jc box-shadow h-30 w-30 bd-radius" style="top:15px;right:15px;" @click.stop="closeInfo">
          <van-icon name="cross" />
        </div>
        <company-info :info="info" :show="showInfo" v-if="infoType === 'company'"></company-info>
        <chanye-info :info="info" :show="showInfo" v-if="infoType === 'chanye'"></chanye-info>
      </div>
    </div>
    <div class="abs bg-light box-shadow" :style="{height:infoHeight+'px',top:infoTop+'px'}" style="left:0;right:0;border-radius: 10px 10px 0 0;z-index: 9;transition: all .3s;" @click="openInfo" v-else>
      <div class="rel flex flex-col h100">
        <div v-if="showInfo" class="bg-light hide abs flex flex-row flex-ai flex-jc box-shadow h-30 w-30 bd-radius" style="top:15px;right:15px;z-index: 2;" @click.stop="closeInfo">
          <van-icon name="cross" />
        </div>
        <div class="flex flex-col pd-l-r-15 pd-t-15 rel">
          <div class="flex flex-row flex-ai flex-jc-between h-30">
            <div class="flex flex-row flex-ai">
              <div class="dark-0">查找企业</div>
              <div class="blue-0 f12 mg-l-20" v-if="showCompany && showInfo" @click="toHideCompany">重置搜索条件</div>
            </div>
            <div class="dark-2 f14" v-if="!showInfo">点击查看更多条件</div>
          </div>
          <van-search :disabled="!showInfo" v-model="searchForm.name" placeholder="请输入搜索关键词" @search="toShowCompany" @clear="toHideCompany" class="mg-t-10" style="padding:0 !important;" clear-trigger="always"/>
          <div v-if="!showInfo" class="abs" style="top:0;left:0;right:0;bottom:0" @click.stop="openInfo"></div>
        </div>
<!--        -->
        <div class="flex1 hide" id="infobody2" :style="{overflowY: showInfo ? 'auto' : 'hidden'}">
          <flat-list url="/Company/listPage" list-class="mg-l-r-15 mg-t-15" :data="searchForm" v-show="showCompany">
            <div class="box-shadow flex flex-row pd-10 hide bd-radius-6 mg-b-20" slot-scope="scope" @click.stop="toAddMarker(scope.row)">
              <div class="flex1 flex flex-col">
                <div class="f16 f-b">{{scope.row.name}}</div>
                <div class="f14 dark-4 text-line-1 mg-t-5" v-if="scope.row.address">{{scope.row.address}}</div>
              </div>
              <van-image
                class="mg-l-10 bd-radius-6 hide"
                v-if="scope.row.logo"
                width="60px"
                height="60px"
                fit="cover"
                :src="scope.row.logo+'!thumb'"
              />
            </div>
          </flat-list>
          <template v-show="!showCompany">
            <van-row gutter="10" class="mg-l-r-15 mg-t-15">
              <van-col span="8">
                <base-picker is-all all-label="全部产业" v-model="searchForm.dict_cy_id" placeholder="全部产业" url="/SystemDict/listAll" :datas="{type:'chanye',orderBy:'sort',sortBy:'asc',state:1}"></base-picker>
              </van-col>
              <van-col span="8">
                <base-picker is-all all-label="全部行业" v-model="searchForm.dict_hy_id" placeholder="全部行业" url="/SystemDict/listAll" :datas="{type:'industry',orderBy:'sort',sortBy:'asc',state:1,pid:searchForm.dict_cy_id}" :auto-load="false"></base-picker>
              </van-col>
              <van-col span="8">
                <base-picker is-all all-label="全部园区" v-model="searchForm.dict_yq_id" placeholder="全部园区" url="/Park/listAll" :datas="{orderBy:'sort',sortBy:'asc',state:1}"></base-picker>
              </van-col>
            </van-row>
            <div class="mg-l-r-15 bg-blue-0 blue-0 text-center f16 bd-radius-4 pd-t-b-10 mg-t-30" @click.stop="toShowCompany">搜索</div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return{
      searchForm:{
        name:'',
        lnglat:'1',
        dict_cy_id: '',
        dict_hy_id: '',
        dict_yq_id: '',
      },

      showCompany:false,

      dictList:[],
      chanyeActive:null,

      hangyeList:[],
      hangyeActive:null,

      map:null,
      layer:null,
      markers:[],
      companyList:[],
      polygons:[],
      texts:[],
      textsList:[],
      opacity:1,
      polygonShow:true,
      AMap:null,

      info:null,
      infoType:'',

      showInfo:false,

      infoHeight:0,
      infoTop:0,
      windowHeight:0,

      centerPos:[114.339084,30.495951],
      zoom:10.2
    }
  },
  created() {
    this.getWindow();
  },
  mounted() {
    this.initmap();

    this.getDictList('chanye');

    // this.getBoxs();

  },
  methods: {
    toCleanSearch(){
      this.companyList = [];
    },

    toAddMarker(e){
      this.hangyeActive = null;
      this.info = null;
      this.infoType = '';

      let _icon = null;

      if(e.hangye && e.hangye.s_img){
        _icon = e.hangye.s_img;
      }else if(e.chanye && e.chanye.s_img){
        _icon = e.chanye;
      }else{
        _icon = null;
      }

      this.cleanMarker();

      this.hideBoxs();

      this.closeInfo();

      this.addMarker(e.lng,e.lat,_icon,e);
    },
    toShowCompany(){
      // this.getCompanyList();
      this.showCompany = true;
    },
    toHideCompany(){
      this.showCompany = false;
    },
    getCompanyList(){
      let _this = this;

      let _data = {lnglat:'1'};

      // _data = {dict_cy_id:this.chanyeActive.id};

      // if(this.hangyeActive){
      //   _data.dict_hy_id = this.hangyeActive.id;
      // }

      this.$http.post({
        url:'/Company/listAll',
        data:this.searchForm,
        success(res){
          _this.companyList = res;
        }
      })
    },
    getWindow(){
      let _winH = document.documentElement.clientHeight;

      this.windowHeight = _winH;

      this.infoHeight = parseInt(_winH - 80);
      this.infoTop = parseInt(_winH - 100);

      console.log(_winH);
    },
    openInfo(){
      console.log('====openinfo====');
      this.showInfo = true;
      this.infoTop = 80;
    },
    closeInfo(){
      console.log('===closeinfo===')
      let _infobody = document.getElementById('infobody');

      if(_infobody) {
        _infobody.scrollTop = 0;
      }

      let _infobody2 = document.getElementById('infobody2');

      if(_infobody2) {
        _infobody2.scrollTop = 0;
      }

      this.showInfo = false;

      this.infoTop = parseInt(this.windowHeight - 100);
    },

    removeInfo(){
      this.info = null;

      // if(this.infoType === 'company'){
      //   this.infoType = 'allCompany';
      // }else {
      //   this.infoType = '';
      // }
      this.showInfo = false;

      if(this.infoType === 'company') {
        this.resetAllMarkerSize();
      }else{
        if(this.markers.length <= 0) {
          // this.cleanMarker();
          //
          // this.addAllText();
          //
          this.changePolygonOpacity(1);
        }
      }

      this.infoType = '';

      // this.map.setZoomAndCenter(11, [114.339256,30.502672],false,300);
    },

    initmap(){
      let _this = this;

      this.$AMapLoader.load({
        key:'95bd277b4685133d32a4a51991623baf',
        version:"2.0",
        plugins:['AMap.MouseTool','AMap.ToolBar'],
      }).then((AMap)=>{
        _this.AMap = AMap;
        _this.map = new AMap.Map("container",{  //设置地图容器id
          // viewMode:"3D",    //是否为3D地图模式
          zoom:_this.zoom,           //初始化地图级别
          center:_this.centerPos, //初始化地图中心点位置
          mapStyle: 'amap://styles/559705f2ed66a9ccf0ad852cb957f15c'
        });

        if(_this.map) {
          _this.map.on('click', function (ev) {
            _this.removeInfo();
          });

          _this.map.addControl(new AMap.ToolBar({position:{bottom:'220px',right:'20px'}}));

          _this.getBoxs();
        }
      }).catch(e=>{
        console.log(e);
      })
    },

    /**
     * 添加多边形
     * @param boxs
     * @param color
     * @param item
     */
    addPolygon(boxs,color,item){
      let _this = this;
      if(boxs.length > 0) {
        // console.log(this.$utils.deepCopy(this.polygons).length);
        // console.log(this.polygons);
        item.index = this.polygons.length;
        // console.log(item.index)

        let polygon = new this.AMap.Polygon({
          path: boxs,
          fillColor: color, // 多边形填充颜色
          strokeWeight: 3, // 线条宽度，默认为 1
          strokeColor: '#fff', // 线条颜色
          fillOpacity:_this.opacity,
          strokeOpacity:_this.opacity,
          extData:item
        });

        polygon.on('click',(e)=>{
          let _target = e.target;
          let _item = _target.getExtData();

          if(_this.infoType === 'company'){
            _this.removeInfo();
          }else{
            if(_this.markers.length > 0){
              return;
            }

            let _index = _item.index;

            _this.cleanMarker();

            if(_this.texts.length <= 0) {
              _this.addAllText();
            }

            _this.infoType = 'chanye';

            _this.changePolygonOpacity(0.3);

            _this.polygons[_index].setOptions({
              strokeColor:'#fff',
              strokeOpacity:1,
              fillOpacity:1
            })

            // if(_item.lng && _item.lat) {
            //   _this.map.setZoomAndCenter(11, [_item.lng, _item.lat], false, 300);
            // }

            _this.polygons[_index].setMap(_this.map);

            _this.info = _item;
          }
        })

        polygon.setMap(this.map);
        this.polygons.push(polygon);
      }
    },

    /**
     * 添加所有文字标注
     */
    addAllText(){
      for (let i=0;i < this.chanyeList.length;i++){
        if(this.chanyeList[i].lng && this.chanyeList[i].lat) {
          this.addText(this.chanyeList[i].lng, this.chanyeList[i].lat, this.chanyeList[i].full_name,this.chanyeList[i]);
        }
      }
    },

    /**
     * 添加文字
     * @param lng
     * @param lat
     * @param text
     * @param item
     */
    addText(lng,lat,text,item){
      let _this = this;

      item.index = this.texts.length;

      let textMarker = new this.AMap.Text({
        position: new this.AMap.LngLat(lng, lat),
        anchor: 'bottom-center',
        text: text,
        style: {'font-size':'12px','background-color':'transparent','border-width':'0px','color':'#363636'},
        extData:item
      });

      textMarker.on('click',(e)=>{
        let _target = e.target;
        let _item = _target.getExtData();

        if(_this.infoType === 'company'){
          _this.removeInfo();
        }else{
          let _index = _item.index;

          _this.cleanMarker();

          if(_this.texts.length <= 0) {
            // _this.addAllText();
          }

          _this.infoType = 'chanye';

          _this.changePolygonOpacity(0.3);

          _this.changePolygonOpacityByIndex(_index,1);

          // if(_item.lng && _item.lat) {
          //   _this.map.setZoomAndCenter(11, [_item.lng, _item.lat], false, 300);
          // }

          _this.info = _item;
          // _this.infoType = 'chanye';
          // _this.info = _item;
        }
      })

      textMarker.setMap(this.map);

      this.texts.push(textMarker);
      // this.map.add(textMarker);
    },

    /**
     * 情况文字标注
     */
    cleanText(){
      for(let i=0;i < this.texts.length;i++) {
        this.texts[i].setMap(null);
      }

      this.texts = [];
    },

    resetAllMarkerSize(){
      for (let i=0;i < this.markers.length;i++){
        let _icon = this.markers[i].getIcon();

        this.markers[i].setTop(false);
        _icon.setSize(new this.AMap.Size(24, 24));
        _icon.setImageSize(new this.AMap.Size(24, 24));

        this.markers[i].setIcon(_icon);
      }
    },

    /**
     * 添加标注
     * @param lng
     * @param lat
     * @param icon
     * @param item
     */
    addMarker(lng,lat,icon,item = null){
      let _this = this;
      if(lng && lat) {
        let startIcon = new this.AMap.Icon({
          size: new this.AMap.Size(24, 24),
          image: icon ? icon : '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
          imageSize: new this.AMap.Size(24, 24),
        });

        let marker = new this.AMap.Marker({
          icon: startIcon,
          position: [lng,lat],
          offset: new this.AMap.Pixel(0, 0),
          anchor:'bottom-center',
          extData:item
        });

        marker.on('click', (e) => {
          // console.log(e);
          // console.log(e.target.getExtData());

          _this.resetAllMarkerSize();

          let _marker = e.target;
          let _icon = _marker.getIcon();

          _this.info = _marker.getExtData();
          _this.infoType = 'company';


          _marker.setTop(true);

          _icon.setSize(new _this.AMap.Size(36, 36))
          _icon.setImageSize(new _this.AMap.Size(36, 36))

          _marker.setIcon(_icon);

          _this.map.setZoomAndCenter(14,[_this.info.lng,_this.info.lat],false,300);
        })

        // this.map.add(marker);

        marker.setMap(this.map);

        this.markers.push(marker);
      }
    },

    /**
     * 情况标注
     */
    cleanMarker(){
      // for(let i=0;i < this.markers.length;i++) {
      //   this.markers[i].setMap(null);
      // }

      this.map.remove(this.markers);

      this.markers = [];
    },

    onConfirm() {
      this.$refs.item.toggle();
    },

    showBoxs(){
      this.changePolygonOpacity(1);

      if(!this.polygonShow) {
        this.polygonShow = true;

        if(this.map) {
          this.map.setZoomAndCenter(this.zoom, this.centerPos,false,300);
        }
      }

      this.cleanMarker();

      this.info = null;
      this.infoType = '';

      this.cleanText();

      this.addAllText();
    },

    hideBoxs(){
      this.changePolygonOpacity(0.3);

      this.polygonShow = false;

      this.cleanText();
    },

    /**
     * 改变产业类型
     * @param e
     */
    changeDict(e){
      this.chanyeActive = e;
      this.hangyeActive = null;
      this.info = null;
      this.infoType = '';

      this.cleanMarker();

      this.hideBoxs();

      this.getPoint(null);

      this.getHyList();

      this.closeInfo();

      if(this.map) {
        this.map.setZoomAndCenter(this.zoom, this.centerPos,false,300);
      }
    },

    /**
     * 改变所有区块透明度
     * @param opacity
     */
    changePolygonOpacity(opacity){
      for (let i=0;i < this.polygons.length;i++) {
        // this.polygons[i].setOptions({
        //   fillOpacity: this.opacity,
        //   strokeOpacity:this.opacity,
        // })
        // this.polygons[i].setMap(this.map);

        this.changePolygonOpacityByIndex(i,opacity);
      }
    },

    /**
     * 改变某一个区块透明度
     * @param index
     * @param opacity
     */
    changePolygonOpacityByIndex(index,opacity){
      if(this.map) {
        this.polygons[index].setOptions({
          strokeOpacity: opacity,
          fillOpacity: opacity
        })

        this.polygons[index].setMap(this.map);
      }
    },


    /**
     * 获取标注并绘制
     * @param img
     */
    getPoint(img){

      let _this = this;

      let _data = {dict_cy_id:this.chanyeActive.id,lnglat:'1'};

      if(this.hangyeActive){
        _data.dict_hy_id = this.hangyeActive.id;
      }

      this.$http.post({
        url:'/Company/listAll',
        data:_data,
        success(res){
          for(let i=0;i < res.length;i++) {
            if(res[i].lng && res[i].lat) {
              // let _item = {
              //   name:res[i].name,
              //   address:res[i].address,
              //
              // };
              let _icon = null;

              if(img){
                _icon = img;
              }else {
                if (res[i].hangye && res[i].hangye.s_img) {
                  _icon = res[i].hangye.s_img;
                } else if (res[i].chanye && res[i].chanye.s_img) {
                  _icon = res[i].chanye;
                } else {
                  _icon = null;
                }
              }

              _this.addMarker(res[i].lng,res[i].lat,_icon,res[i]);
            }
          }
        }
      })
    },

    changeHangye(e){
      this.hangyeActive = e;
      this.info = null;
      this.infoType = '';

      this.$refs.item.toggle();

      this.cleanMarker();

      this.hideBoxs();

      this.getPoint(this.hangyeActive.s_img);

      this.closeInfo();

      if(this.map) {
        this.map.setZoomAndCenter(this.zoom, this.centerPos,false,300);
      }
    },

    /**
     * 获取产业分类
     * @param type
     */
    getDictList(type){
      let _this = this;

      this.$http.post({
        url:'/SystemDict/listAll',
        data:{type:type,orderBy:'sort',sortBy:'asc',state:1,limit:4},
        success(res){
          _this.dictList = res;

          if(type === 'chanye'){
            _this.chanyeActive = res[0];

            // _this.getPoint(res[0].s_img);

            _this.getHyList();
          }
        }
      })
    },

    /**
     * 获取行业分类
     */
    getHyList(){
      let _this = this;

      this.$http.post({
        url:'/SystemDict/listAll',
        data:{type:'industry',orderBy:'sort',sortBy:'asc',state:1,pid:this.chanyeActive.id},
        success(res){
          _this.hangyeList = res;
        }
      })
    },

    /**
     * 获取区块
     */
    getBoxs(){
      let _this = this;

      this.$http.post({
        url:'/SystemDict/listAll',
        data:{type:'chanye',orderBy:'sort',sortBy:'asc',state:1},
        success(res){
          _this.chanyeList = res;

          for(let i=0;i < res.length;i++){
            _this.addPolygon(res[i].boxs,res[i].bgcolor,res[i]);
            // _this.textsList.push({text:res[i].name,lng:res[i].lng,lat:res[i].lat});
          }

          _this.addAllText();
        }
      })
    }
  },
}
</script>
