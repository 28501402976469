<template>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            :finished-text="list.length > 0 ? '没有更多了' : ''"
            @load="getMore">
            <div :class="listClass">
                <template v-if="list.length > 0">
                    <slot v-for="(item,index) in list" :row="item"></slot>
                </template>
                <van-empty description="暂无数据" v-else-if="finished && list.length <= 0"/>
            </div>
        </van-list>
    </van-pull-refresh>
</template>

<script>
export default {
    name: "FlatList",
    data(){
        return{
            refreshing: false,
            loading: false,
            finished: false,
            list:[],
            page:1,
            totalCount:0,
            totalPage:0,
        }
    },
    props:{
        url:{
            type:String,
            default:'',
        },
        data:{
            type:[Array,Object],
            default(){
                return {}
            }
        },
        pageSize:{
            type:[String,Number],
            default:10
        },
        listClass:{
            type:String,
            default:'',
        },
        auto:{
            type:Boolean,
            default:true,
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getMore(){
            if(!this.finished) {
                this.page += 1;
                this.getList();
            }
        },
        getList(refresh = false){
            let _this = this;

            this.loading = true;

            this.$http.post({
                url:this.url,
                data:{...this.data,page:this.page,pageSize:this.pageSize},
                success(res){
                    if(refresh){
                        _this.list = [];

                        setTimeout(()=>{
                            _this.refreshing = false;

                            for(let i=0;i < res.list.length;i++){
                                _this.list.push(res.list[i]);
                            }

                        },2000)

                    }else{
                        for(let i=0;i < res.list.length;i++){
                            _this.list.push(res.list[i]);
                        }
                    }

                    _this.totalCount = res.totalCount;
                    _this.totalPage = res.totalPage;

                    _this.loading = false;

                    // 数据全部加载完成
                    if (_this.page >= res.totalPage) {
                        _this.finished = true;
                    }
                }
            })
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getList(true);
        },
    },
    watch:{
        data: {
            handler(n, o) {
                if(this.auto) {
                    if(JSON.stringify(n) !== JSON.stringify(o)) {
                        this.page = 1;
                        this.list = [];
                        this.finished = false;
                        this.getList();
                    }
                }
            },
            deep: true
        }
    }
}
</script>
