<template>
  <div class="flex1 flex flex-col hide" v-if="info">
    <div class="bd-radius flex flex-row flex-ai flex-jc bg-gray-3 hide" style="height: 14px;width:50px;margin:5px auto 0 auto;" v-if="!showInfo">
      <van-icon name="arrow-up" class="dark-4" size="12px"/>
    </div>
    <div class="flex flex-col pd-l-r-15 pd-t-20" :style="{marginTop:showInfo ? '' : '-20px'}">
      <div class="mg-r-40 f-b lh-30 dark-0">{{info.name}}</div>
    </div>
    <div class="pd-15 h100" id="infobody" :style="{overflowY: showInfo ? 'auto' : 'hidden'}">
      <div class="bd-l-w-2 bd-style-solid bd-blue-2 f14 pd-l-10">基本信息</div>
      <div class="c-bg-dark-10 pd-10 mg-t-10 bd-radius-6">
        <div class="flex flex-row flex-ai pd-t-b-5" v-if="info.quyu">
          <div class="dark-2 f14" style="min-width: 100px;">所属区域</div>
          <div class="dark-1 f14 flex1 mg-l-5">{{info.quyu.name}}</div>
        </div>
        <div class="flex flex-row flex-ai pd-t-b-5" v-if="info.chanye">
          <div class="dark-2 f14" style="min-width: 100px;">所属产业</div>
          <div class="dark-1 f14 flex1 mg-l-5">{{info.chanye.name}}</div>
        </div>
        <div class="flex flex-row flex-ai pd-t-b-5" v-if="info.hangye">
          <div class="dark-2 f14" style="min-width: 100px;">所属行业</div>
          <div class="dark-1 f14 flex1 mg-l-5">{{info.hangye.name}}</div>
        </div>
        <div class="flex flex-row flex-ai pd-t-b-5" v-if="info.park">
          <div class="dark-2 f14" style="min-width: 100px;">所在园区</div>
          <div class="dark-1 f14 flex1 mg-l-5">{{info.park.name}}</div>
        </div>
        <div class="flex flex-row flex-ai pd-t-b-5" v-if="info.nature">
          <div class="dark-2 f14" style="min-width: 100px;">企业性质</div>
          <div class="dark-1 f14 flex1 mg-l-5">{{info.nature.name}}</div>
        </div>
      </div>
      <div class="bd-l-w-2 bd-style-solid bd-blue-2 f14 dark-0 pd-l-10 mg-t-20" v-if="info.content">企业介绍</div>
      <div class="f14 lh-20 dark-1 mg-t-15" v-if="info.content">{{info.content}}</div>
      <template v-if="info.address">
        <div class="dark-0 bd-l-w-2 bd-style-solid bd-blue-2 f14 pd-l-10 mg-t-20">联系我们</div>
        <div class="f14 lh-20 dark-1 mg-t-15" v-if="info.address">{{info.address}}</div>
<!--        <span class="inline-block flex flex-row flex-ai c-bg-blue-1 light bd-radius f12 pd-l-r-10 pd-t-b-5 mg-t-b-15" @click.stop="toMap">-->
<!--            <van-icon name="guide-o" size="16px" class="mg-r-5"/>一键导航-->
<!--        </span>-->
      </template>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  name: 'CompanyInfo',
  data(){
    return{
      showInfo:false
    }
  },
  props: {
    info: {
      type:Object,
      default:null
    },
    show:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    toMap(){
      if(this.info){
        this.postMessage('openLocation',{latitude:this.info.lat, longitude:this.info.lng, scale: 18})
        // wx.miniProgram.openLocation({
        //   latitude:this.info.lat,
        //   longitude:this.info.lng,
        //   scale: 18
        // })
      }
    },
    postMessage(event,data){
      console.log(event,data);
      wx.miniProgram.postMessage({data:{event:event,data:data}});
    }
  },
  watch:{
    show(n,o){
      this.showInfo = n;
    }
  }
}
</script>
