export default {
    // apiUrl:'http://api.test.hc-chaincloud.org.cn/api/',
    // uploadUrl:'http://192.168.31.9:9501/Admin/Upload/upload',
    // assetsUrl:'http://test.hc-chaincloud.org.cn/public',

    // apiUrl:'http://192.168.31.9:9501/Api',
    // uploadUrl:'http://192.168.31.9:9501/Api/Upload/upload',
    // assetsUrl:'http://192.168.31.9:9501',
    apiUrl:'//hs.api.zcshome.com/Api',
    uploadUrl:'//hs.api.zcshome.com/Api/Upload/upload',
    assetsUrl:'//hs.api.zcshome.com/static',
}
