<template>
  <div class="flex1 flex flex-col hide" v-if="info">
    <div class="bd-radius flex flex-row flex-ai flex-jc bg-gray-3 hide" style="height: 14px;width:50px;margin:5px auto 0 auto;" v-if="!showInfo">
      <van-icon name="arrow-up" class="dark-4" size="12px"/>
    </div>
    <div class="flex flex-col pd-l-r-15 pd-t-20" :style="{marginTop:showInfo ? '' : '-20px'}">
      <div class="mg-r-40 f-b lh-30 dark-0">{{info.full_name}}</div>
    </div>
    <div class="pd-15 h100" id="infobody" :style="{overflowY: showInfo ? 'auto' : 'hidden'}">
      <div class="dark-1 f14" :class="more && showInfo ? '' : 'text-line-2'" @click="toggleMore">{{info.des}}</div>
      <div class="f12 mg-t-10" :class="more ? 'dark-2' : 'blue-0'" @click="toggleMore">{{more ? '-收起' : '-展开全部'}}</div>
      <div v-if="companyList.length > 0">
        <div class="bd-l-w-2 bd-style-solid bd-blue-1 pd-l-5 mg-b-10 mg-t-20 flex flex-row flex-jc-between flex-ai">
          <div class="f12 dark-0">重点企业</div>
          <div class="blue-0 f12" @click="toCompanyList">更多</div>
        </div>
        <div class="flex flex-row pd-10 box-shadow mg-b-15 bd-radius-6 hide" v-for="(item,index) in companyList" @click="toCompanyDetail(item)">
          <img class="vm mg-r-10 bd-radius-4 hide bd-w-1 bd-style-solid bd-dark-10" :src="item.logo+'!thumb'" style="height: 46px;width:46px;"/>
          <div class="flex flex-col">
            <div class="dark-1 f14">{{item.name}}</div>
            <div class="mg-t-5">
              <span class="inline-block bg-red-0 red-0 f12 mg-r-5 mg-b-5" style="padding:1px 5px;border-radius: 3px;" v-if="item.is_top === 1">龙头企业</span>
              <span class="inline-block bg-gray-4 dark-2 f12 mg-r-5 mg-b-5" style="padding:1px 5px;border-radius: 3px;" v-if="item.chanye && item.chanye.name">{{item.chanye.name}}</span>
              <span class="inline-block bg-gray-4 dark-2 f12 mg-r-5 mg-b-5" style="padding:1px 5px;border-radius: 3px;" v-if="item.hangye && item.hangye.name">{{item.hangye.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="newsList.length > 0">
        <div class="bd-l-w-2 bd-style-solid bd-blue-1 pd-l-5 mg-t-b-20 flex flex-row flex-jc-between flex-ai">
          <div class="f12 dark-0">相关政策</div>
          <div class="blue-0 f12" @click="toNewsList">更多</div>
        </div>
        <div class="flex flex-row pd-10 box-shadow mg-b-20 bd-radius-6 hide" v-for="(item,index) in newsList" @click="toNewsDetail(item)">
          <div class="f14 dark-1">{{item.title}}</div>
          <img :src="item.head_img+'!thumb'" style="height:54px;width:54px;border-radius: 3px;" class="vm mg-l-10 hide" v-if="item.head_img"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  name: "ChanyeInfo",
  data(){
    return{
      showInfo:false,
      companyList:[],
      newsList:[],
      more:false
    }
  },
  props: {
    info: {
      type:Object,
      default:null
    },
    show:{
      type:Boolean,
      default:false
    }
  },
  mounted() {
    this.getCompanyList();
    this.getNewsList();
  },
  methods:{
    toCompanyList(){
      wx.miniProgram.navigateTo({
        url: '/pages/company/list/list?cyid='+this.info.id
      })
    },

    toCompanyDetail(item){
      if(item) {
        wx.miniProgram.navigateTo({
          url: '/pages/company/detail/detail?id=' + item.id
        })
      }
    },

    toNewsList(){
      wx.miniProgram.switchTab({
        url: '/pages/policy/index/index'
      })
    },

    toNewsDetail(item){
      if(item) {
        wx.miniProgram.navigateTo({
          url: '/pages/policy/detail/detail?id=' + item.id
        })
      }
    },

    toggleMore(){
      if(this.show) {
        this.more = !this.more;
      }
    },
    getCompanyList(){
      let _this = this;

      this.$http.post({
        url:'/Company/listAll',
        data:{dict_cy_id:this.info.id,is_top:1,limit:6},
        success(res){
          _this.companyList = res;
        }
      })
    },
    getNewsList(){
      let _this = this;

      this.$http.post({
        url:'/News/listAll',
        data:{dict_cy_ids:this.info.id,limit:6},
        success(res){
          _this.newsList = res;
        }
      })
    }
  },
  watch:{
    show(n,o){
      this.showInfo = n;
    },
    info:{
      handler(n,o){
        this.getCompanyList();
      },
      deep:true
    }
  }
}
</script>
